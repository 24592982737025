*, div{
  font-family: 'Inter', sans-serif;
}

.square-checkbox {
  display: flex;
  position: relative;
}

.square-checkbox input[type="checkbox"] {
  display: none;
}

.square-checkbox label {
  width: 16px;
  height: 16px;
  display: inline-block;
  background-color: transparent;
  border: 1px solid #F8FAFC; 
  border-radius: 2px;
  cursor: pointer;
}

.square-checkbox input[type="checkbox"]:checked + label {
  border: 1px solid #6EE7B7; 
  background-color: #6EE7B7;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16"><path d="M13.354 4.354a.5.5 0 0 0-.708-.708L6 10.293 3.354 7.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
}
input:focus {
  outline: none;
}
.input-style input:focus ~ span {
  color: #6EE7B7;
  font-size: 12px;
  top: 8px;
}
.input-style input:focus {
  padding-bottom: 8px;
  padding-top: 24px;
  border: 1px solid #6EE7B7 !important;
}
.input-style span {
  transition: all 0.3s ease-in-out;
}
.input-style input {
  transition: all 0.3s ease-in-out;
  background: transparent !important;
  border: 1px solid #0F172A;
}
.spinner{
  font-size: 28px;
  widtH: 1em;
  height: 1em;
  fill: transparent;
  stroke: #6EE7B7;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-dasharray: 125;
  animation: spinner 2s linear infinite;
}

@keyframes spinner{
  0%{
    stroke-dashoffset: 25;
    transform: rotate(0deg);
  }
  50%{
    stroke-dashoffset: 125;
    transform: rotate(720deg);
  }
  100%{
    stroke-dashoffset: 25;
    transform: rotate(1080deg);
  }
}

.spinner > circle{
  fill: transparent;
}

.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: RGBA(248,250,252,0.2);

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(87, 87, 87, 0) 0,
      rgba(87, 87, 87, 0.2) 20%,
      rgba(87, 87, 87, 0.4) 60%,
      rgba(87, 87, 87, 0)
    );
    animation: shimmer 3s infinite;
    content: '';
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.hover-google:hover ~ .google-btn {
  color: #94A3B8;
  border-color: #94A3B8;
  transition: all 0.3s ease-in-out;
}
nav {
  z-index: 1001 !important;
  position: fixed !important;
}